import React from "react"
import { Link } from "gatsby"


class SubPageMenu extends React.Component {

  render() {

    return (

      <ul className={`flex flex-wrap mx-auto justify-center ${this.props.contMargins}`}>
        {this.props.pages.map(d => (
          d.url === "none" ? (
            <li key={d.name}>
              <button onClick={d.stateHandler} className={`${this.props.btnPadding} mx-2 mt-3 leading-6 cursor-pointer ${this.props.btnTextSize} rounded-full transition-300 focus:outline-none ${d.active ? 'bg-highlight text-noir' : 'bg-gray-800 text-gray-400 hover:bg-gray-300 focus:bg-gray-700 hover:text-noir'}`}>
                {d.name}
              </button>
            </li>
          )
          : <li key={d.name}>
              <Link className={`block ${this.props.btnPadding} mx-2 mt-3 leading-6 ${this.props.btnTextSize} rounded-full cursor-pointer transition-300 focus:outline-none ${d.active ? 'bg-highlight' : 'bg-gray-800 hover:bg-gray-300 focus:bg-gray-700 hover:text-noir'}`} to={d.url}>{d.name}</Link>
            </li>
        ))}
      </ul>

    )
  }
}

SubPageMenu.defaultProps = {
  contMargins: "mt-5 md:mt-10 xl:mt-20 md:mb-4 xl:mb-6",
  btnPadding: "px-4 py-2",
  btnTextSize: "text-sm",
};

export default SubPageMenu